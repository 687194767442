import { GUEST_EMAIL } from 'Component/CheckoutSuccess/CheckoutSuccess.config';
import { CART_TOTALS } from 'Store/Cart/Cart.reducer';
import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getQueryParam } from 'Util/Url';

/** @namespace Scandipwa/Util/Scarab/pushRecommenderData */
export const pushRecommenderData = (locale, createArrayOfIds, isKsa, containerId, items) => {
    window.ScarabQueue.push(['cart',
        items.map((product) => (
            {
                item: product.sku,
                price: product.prices.row_total_including_tax.value / product.quantity,
                quantity: product.quantity
            }
        ))
    ]);
    window.ScarabQueue.push(['availabilityZone', locale]);
    window.ScarabQueue.push(['recommend', {
        logic: isKsa ? 'HOME_LOGIC_HOMEPAGE_KSA' : 'HOME_LOGIC_HOMEPAGE',
        limit: 10,
        containerId,
        success(SC) {
            createArrayOfIds(SC.page.products.map((product) => product.id));
        }
    }]);
    window.ScarabQueue.push(['go']);
};

/** @namespace Scandipwa/Util/Scarab/pushScarabPurchaseEvent */
export const pushScarabPurchaseEvent = (orderId) => {
    if (window.ScarabQueue) {
        const email = isSignedIn()
            ? BrowserDatabase.getItem(CUSTOMER)?.email
            : BrowserDatabase.getItem(GUEST_EMAIL);

        const { items = [] } = BrowserDatabase.getItem(CART_TOTALS);
        const itemsData = items.map((item) => ({
            item: item.sku,
            price: item.prices.row_total_including_tax.value,
            quantity: item.quantity
        }));

        window.ScarabQueue.push(['setEmail', email]);
        window.ScarabQueue.push(['cart', []]);
        window.ScarabQueue.push(['purchase', {
            orderId,
            items: itemsData
        }]);
        window.ScarabQueue.push(['go']);
    }
};

/** @namespace Scandipwa/Util/Scarab/pushScarabCartEvent */
export const pushScarabCartEvent = () => {
    if (window.ScarabQueue) {
        const cartItems = BrowserDatabase.getItem(CART_TOTALS)?.items || [];
        const items = cartItems.map((item) => ({
            item: item.sku,
            price: item.prices.row_total_including_tax.value,
            quantity: item.quantity
        })) || [];

        window.ScarabQueue.push(['cart', items]);
    }
};

/** @namespace Scandipwa/Util/Scarab/pushScarabSearchEvent */
export const pushScarabSearchEvent = () => {
    if (window.ScarabQueue) {
        const searchTerm = getQueryParam('q', window.location);

        if (!searchTerm) {
            return;
        }

        window.ScarabQueue.push(['searchTerm', searchTerm]);
    }
};
