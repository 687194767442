/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DESKTOP_FORGOT_PASSWORD_POPUP } from 'Component/ForgotPassword/ForgotPassword.config';
import {
    PASSWORD_LENGTH, STATE_LOGGED_IN
} from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import { CHECKOUT_URL, SHIPPING_URL } from 'Route/Checkout/Checkout.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountSignInContainer as SourceMyAccountSignInContainer
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.container';
import { hideActiveOverlay, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import transformToNameValuePair from 'Util/Form/Transform';
import history from 'Util/History';
import { getErrorMessage } from 'Util/Request';
import { appendWithStoreCode } from 'Util/Url';

import MyAccountSignInComponent from './MyAccountSignIn.component';
import { SIGN_EMAIL_ID, SIGN_PASSWORD_ID } from './MyAccountSignIn.config';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Scandipwa/Component/MyAccountSignIn/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    locale: state.ConfigReducer.locale,
    minimunPasswordLength: state.ConfigReducer.minimun_password_length
});

/** @namespace Scandipwa/Component/MyAccountSignIn/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
    showPopup: (payload) => dispatch(showPopup('title', payload)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace Scandipwa/Component/MyAccountSignIn/Container */
export class MyAccountSignInContainer extends SourceMyAccountSignInContainer {
    static propTypes = {
        ...super.propTypes,
        showPopup: PropTypes.func.isRequired,
        showOverlay: PropTypes.func.isRequired,
        isSignedIn: PropTypes.bool.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        isNoTitle: PropTypes.bool,
        locale: PropTypes.string.isRequired,
        minimunPasswordLength: PropTypes.number.isRequired
    };

    static defaultProps = {
        ...super.defaultProps,
        isCheckout: false,
        isNoTitle: false
    };

    state = {
        ...this.state,
        emailLogin: '',
        isSignInDisabled: true,
        passwordLogin: '',
        showForgotPasswordPopup: false,
        isLoading: false
    };

    stateMap = {
        [SIGN_EMAIL_ID]: 'emailLogin',
        [SIGN_PASSWORD_ID]: 'passwordLogin'
    };

    containerFunctions = {
        ...this.containerFunctions,
        handleForgotPassword: this.handleForgotPassword.bind(this),
        onDataChange: this.onDataChange.bind(this),
        onSignInSuccess: this.onSignInSuccess.bind(this)
    };

    containerProps() {
        const {
            isNoTitle,
            minimunPasswordLength,
            locale,
            isFromCheckout
        } = this.props;
        const { isSignInDisabled, showForgotPasswordPopup } = this.state;

        return {
            ...super.containerProps(),
            isNoTitle,
            minimunPasswordLength,
            isSignInDisabled,
            showForgotPasswordPopup,
            locale,
            isFromCheckout
        };
    }

    componentDidMount() {
        const { emailValue } = this.props;

        if (emailValue && emailValue !== '') {
            this.setState({ emailLogin: emailValue });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { isSignedIn: prevIsSignedIn } = prevProps;
        const { state: oldMyAccountState } = prevState;
        const { state: newMyAccountState } = this.state;
        const { location: { pathname } } = history;

        const {
            isSignedIn,
            hideActiveOverlay,
            showNotification
        } = this.props;

        this.enableDisableSignIn();

        if (oldMyAccountState === newMyAccountState) {
            return;
        }

        if (isSignedIn !== prevIsSignedIn) {
            if (isSignedIn) {
                showNotification('success', __('You are successfully logged in!'));
            } else {
                showNotification('success', __('You are successfully logged out!'));
            }

            hideActiveOverlay();
        }

        if (!pathname.includes(CHECKOUT_URL) && newMyAccountState === STATE_LOGGED_IN) {
            history.push({ pathname: appendWithStoreCode('/customer/account') });
        }
    }

    enableDisableSignIn() {
        const {
            emailLogin,
            passwordLogin
        } = this.state;

        if (emailLogin
            && passwordLogin
            && passwordLogin.length > PASSWORD_LENGTH) {
            this.setState({ isSignInDisabled: false });
        } else {
            this.setState({ isSignInDisabled: true });
        }
    }

    async onSignInSuccess(form, fields) {
        const {
            isCheckout,
            signIn,
            showNotification,
            onSignIn,
            setLoadingState
        } = this.props;

        const { isSignIn, emailLogin } = this.state;

        setLoadingState(true);

        const fieldPairs = transformToNameValuePair(fields);

        if (!isSignIn) {
            this.setState({ isSignIn: true });

            try {
                await signIn(fieldPairs);
                onSignIn();

                if (window.pageData) {
                    if (window.pageData.customer) {
                        window.pageData.customer.email = emailLogin;
                    } else {
                        window.pageData.customer = { email: emailLogin };
                    }

                    window.pageData.customer.authenticated = true;
                }
            } catch (error) {
                showNotification('error', __(getErrorMessage(error)));
                this.setState({ isSignIn: false });
            } finally {
                setLoadingState(false);
            }
        } else {
            setLoadingState(false);
        }

        if (isCheckout) {
            history.push({ pathname: appendWithStoreCode(SHIPPING_URL) });
        }
    }

    onDataChange(event, field) {
        const { value = '', id } = field;
        const stateField = this.stateMap[id];
        const state = { [stateField]: value };

        this.setState(state);
    }

    handleForgotPassword() {
        const { showOverlay, showPopup } = this.props;

        this.setState({ showForgotPasswordPopup: true }, () => {
            showPopup(__('Forgot your password?'));
            showOverlay(DESKTOP_FORGOT_PASSWORD_POPUP);
        });
    }

    render() {
        return (
            <MyAccountSignInComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountSignInContainer);
