/* eslint-disable max-lines */
/* eslint-disable import/no-cycle,@scandipwa/scandipwa-guidelines/use-namespace,no-magic-numbers */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import { ONE_MONTH_IN_SECONDS } from 'Store/MyAccount/MyAccount.dispatcher';
import BrowserDatabase from 'Util/BrowserDatabase';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';
import { appendWithStoreCode } from 'Util/Url';

import MagentoQuery from '../../../query/Magento.query';
import Event, {
    EVENT_GTM_GENERAL_GA4,
    EVENT_GTM_META_UPDATE
} from '../../../util/Event';
import { isEventEnabled } from '../../../util/EventConfig';
import {
    DESKTOP_VIEWPORT,
    MOBILE_BP,
    MOBILE_VIEWPORT,
    TABLET_BP,
    TABLET_VIEWPORT
} from '../events/PageDataEvent/Handlers/NavigationPageDataEvent.handler';
import BaseEventEvent from './BaseEvent.event';

export const GENERAL_EVENT_DELAY = 500;

export const LOCALE_PATTERN = /(.{2})_(.{2})/;
export const CHECKOUT_SUCCESS = '/checkout/success';
export const HASHED_EMAIL = 'hashed_email';
export const MAGENTO_VERSION = 'magento_version';
export const CUSTOMER = 'customer';
export const DEVELOPMENT = 'development';

/**
 * GTM PWA General event
 *
 * On: page load, page change location
 */
export class GeneralEvent extends BaseEventEvent {
    /**
     * If already loading data, do not do second request
     *
     * @type {boolean}
     */
    isLoadingData = false;

    /**
     * Set base event call delay
     *
     * @type {number}
     */
    eventHandleDelay = 0;

    /**
     * Current meta data
     *
     * @type {{}}
     */
    currentMeta = { title: '' };

    /**
     * Bind PWA event handling
     */
    bindEvent() {
        Event.observer(EVENT_GTM_GENERAL_GA4, () => {
            this.handle();
        });

        this.fetchMagentoVersion();

        if (this.isSignedIn()) {
            this.hashCustomerEmail();
        }

        this.saveCartDataToStorage();

        // eslint-disable-next-line prefer-destructuring
        const history = this.getGTM().props.history;

        // eslint-disable-next-line fp/no-let
        let prevLocation = history.location;

        history.listen((location) => { // On page change
            const { pathname } = location;
            const { pathname: prevPathname } = prevLocation;

            // prevents from firing general on filter change (PLP) and on attribute change (PDP)
            // prevents from firing multiple general when checkout redirect happens from /checkout to /checkout/shipping
            if (
                pathname === prevPathname
                || pathname === appendWithStoreCode(CHECKOUT_URL)
            ) {
                return;
            }

            this.saveCartDataToStorage();
            prevLocation = location;

            setTimeout(() => {
                Event.dispatch(EVENT_GTM_GENERAL_GA4, {});
            }, GENERAL_EVENT_DELAY);
        });

        // Receive current meta
        Event.observer(EVENT_GTM_META_UPDATE, (meta) => {
            this.currentMeta = meta;
        });
    }

    saveCartDataToStorage() {
        const storage = this.getStorage() || {};
        storage.cartLast = storage.cart;
        storage.cart = this.getAppState().CartReducer.cartTotals.items;
        this.setStorage(storage);
    }

    /**
     * Handler General
     */
    handler() {
        if (!isEventEnabled(EVENT_GTM_GENERAL_GA4)) {
            return;
        }

        const cartItems = this.prepareCartData().map(
            (item, index) => ({
                ...item,
                index
            })
        );

        this.pushEventData({
            pageType: this.getPageType(),
            customerId: this.getCustomerEmailHash(),
            customerProfileID: this.getCustomerId(),
            customerAuth: this.isSignedIn(),
            customerEmail: this.getCustomerEmail(),
            items_qty: this.getItemsQty(),
            items_total: this.getItemsTotal(),
            pageTitle: this.getPageTitle(),
            environment: this.getEnvironment(),
            viewport: this.getViewport(),
            releaseVersion: this.getReleaseVersion(),
            frontend: `Magento ${this.getMagentoVersion()}`,
            gsmCode: this.getGSMCode(),
            country: this.getCountryName(),
            language: this.getLocale(),
            locale: this.getLocale(),
            ecommerce: {
                cart: {
                    products: cartItems
                }
            }
        });
    }

    /**
     * Get page title
     *
     * @return {string}
     */
    getPageTitle() {
        return this.getAppState().MetaReducer.title || '';
    }

    /**
     * Get current environment
     *
     * @return {string}
     */
    getEnvironment() {
        return process.env.NODE_ENV || DEVELOPMENT;
    }

    /**
     * Returns viewport type according to window width
     *
     * @returns {string}
     */
    getViewport() {
        const { innerWidth: windowWidth } = window;

        if (windowWidth < MOBILE_BP) {
            return MOBILE_VIEWPORT;
        }

        if (windowWidth < TABLET_BP) {
            return TABLET_VIEWPORT;
        }

        return DESKTOP_VIEWPORT;
    }

    /**
     * Get release version
     *
     * @return {string}
     */
    getReleaseVersion() {
        return window.pageData?.navigation?.releaseVersion || '';
    }

    /**
     * Get cart items quantity
     *
     * @return {number}
     */
    getItemsQty() {
        return this.getAppState().CartReducer.cartTotals.total_quantity || 0;
    }

    /**
     * Get current store view
     *
     * @return {string}
     */
    getStoreView() {
        return this.getAppState().ConfigReducer.code || '';
    }

    /**
     * @param {*} item
     */
    getQuantity({ qty }) {
        return qty;
    }

    /**
     * Get current locale
     *
     * @return {string}
     */
    getLocale() {
        const { ConfigReducer: { locale, code } } = this.getAppState();

        return locale || code || '';
    }

    /**
     * Get current signed in customer id
     *
     * @return {string}
     */
    getCustomerId() {
        return this.isSignedIn() ? BrowserDatabase.getItem(CUSTOMER)?.id || '' : '';
    }

    /**
     * Get current signed in customer email
     *
     * @return {string}
     */
    getCustomerEmail() {
        return this.isSignedIn() ? BrowserDatabase.getItem(CUSTOMER)?.email || '' : '';
    }

    /**
     * Get customer city
     *
     * @return {string}
     */
    getCountryName() {
        return this.getAppState().ConfigReducer.default_country || '';
    }

    /**
     * Get storefront GSM code
     *
     * @return {string}
     */
    getGSMCode() {
        return this.getAppState().ConfigReducer.gtm.gsm_code || '';
    }

    /**
     * Get Magento version
     *
     * @return {string}
     */
    getMagentoVersion() {
        return BrowserDatabase.getItem(MAGENTO_VERSION);
    }

    /**
     * Request magento version
     */
    async fetchMagentoVersion() {
        const preparedQuery = prepareQuery([MagentoQuery.getVersion()]);
        const { getMagentoVersion } = await executeGet(preparedQuery, MAGENTO_VERSION, ONE_MONTH_IN_SECONDS);

        BrowserDatabase.setItem(getMagentoVersion[0], MAGENTO_VERSION);
    }

    /**
     * Get email hash of the logged-in user
     *
     * @returns {string|*}
     */
    getCustomerEmailHash() {
        return this.isSignedIn() ? BrowserDatabase.getItem(HASHED_EMAIL) || '' : '';
    }

    /**
     * Creates a SHA-256 hash of a string
     */
    async hashCustomerEmail() {
        // encode as UTF-8
        const msgBuffer = new TextEncoder().encode(this.getCustomerEmail());

        // hash the message
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

        // convert ArrayBuffer to Array
        const hashArray = Array.from(new Uint8Array(hashBuffer));

        // convert bytes to hex string
        // eslint-disable-next-line no-magic-numbers
        const hash = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

        BrowserDatabase.setItem(hash, HASHED_EMAIL);
    }
}

export default GeneralEvent;
